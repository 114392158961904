import "./App.css";
import React, { useEffect, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
//import { Ref } from "react";
import { Image } from "react-bootstrap";

function App() {
    function trackMetrics(titleLabel, metricSource, metricTitle, notes) {
        console.log(
            titleLabel + ":" + metricSource + ":" + metricTitle + ":" + notes
        );
        window.AddGoogleAnalyticsMetric(metricSource, metricTitle, titleLabel);
    }

    //Modal stuff
    const [showOne, setShowOne] = useState(false);
    const [showTwo, setShowTwo] = useState(false);
    const [showThree, setShowThree] = useState(false);
    const [showFour, setShowFour] = useState(false);
    const handleCloseOne = () => setShowOne(false);
    const handleShowOne = () => setShowOne(true);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);
    const handleCloseThree = () => setShowThree(false);
    const handleShowThree = () => setShowThree(true);
    const handleCloseFour = () => setShowFour(false);
    const handleShowFour = () => setShowFour(true);
    const videoNode = React.useRef(null);
    //const [player, setPlayer] = useState(null);
    const [visibleDivs, setVisibleDivs] = useState([]);

    const divConfig = [
        {
            id: 0,
            start: 11,
            end: 22.25,
            posX: 2,
            posY: 82,
            width: 47,
            height: 30,
            divID: "hotspot-1",
            titleLabel: "Promotional Financing Hotspot",
            metricSource: "video-hotspot",
            metricTitle: "Interactive-Hotspot-1",
            notes: "This should track as a goal",
        },
        {
            id: 1,
            start: 32,
            end: 43.25,
            posX: 2,
            posY: 82,
            width: 47,
            height: 30,
            divID: "hotspot-2",
            titleLabel: "Patient Financing Hotspot",
            metricSource: "video-hotspot",
            metricTitle: "Interactive-Hotspot-2",
            notes: "This should track as a goal",
        },
        /*{
      id: 2,
      start: 42,
      end: 56.75,
      posX: 2,
      posY: 82,
      width: 47,
      height: 30,
      divID: "hotspot-3",
      titleLabel: "Overcome Cost Concerns Hotspot",
      metricSource: "video-hotspot",
      metricTitle: "Interactive-Hotspot-3",
      notes: "This should track as a goal",
    },*/
        {
            id: 3,
            start: 57,
            end: 67.5,
            posX: 2,
            posY: 82,
            width: 47,
            height: 30,
            divID: "hotspot-4",
            titleLabel: "Contactless Financing Hotspot",
            metricSource: "video-hotspot",
            metricTitle: "Interactive-Hotspot-4",
            notes: "This should track as a goal",
        },
        // Add more configurations as needed
    ];
    var vjsVideo;
    var once = false;

    /* Runs every frame while the video is playing */
    useEffect(() => {
        /* Establish the video player */
        const vjsPlayer = videojs(videoNode.current);

        /*Dynamicall set visibility of programmable buttons*/
        const checkDivVisibility = () => {
            const currentTime = vjsPlayer.currentTime();
            const newVisibility = divConfig.map(
                (config, index) =>
                    currentTime > config.start && currentTime < config.end
            );
            setVisibleDivs(newVisibility);
        };

        /* Setup functionality for audio buttons */
        const currentTime = vjsPlayer.currentTime();
        if (currentTime > 5 && currentTime < 6) {
            vjsPlayer.currentTime(3);
        }
        if (currentTime > 3 && currentTime < 6) {
            document
                .getElementById("button-section-2")
                .classList.remove("hidden");
        }
        if (currentTime > 7) {
            document
                .getElementsByClassName("vjs-control-bar")[0]
                .classList.remove("hidden");
        }

        vjsPlayer.on("timeupdate", checkDivVisibility);

        /* Set listener for when the video begins playing */
        vjsPlayer.on("play", () => {
            document.getElementById("end-section").classList.add("hidden");
            document
                .getElementById("button-section-2")
                .classList.remove("unclickable");
            if (!once) {
                //console.log("play function");
                vjsVideo = document.getElementById("main-video");

                once = true;
                var pips = document.getElementsByClassName("pip");
                for (var i = 0; i < pips.length; i++) {
                    pips[i].remove();
                }
                /*Generate pips on timeline */
                divConfig.map((config, index) => {
                    document
                        .getElementsByClassName("vjs-progress-holder")[0]
                        .insertAdjacentHTML(
                            "beforeend",
                            "<div class='pip' style='left: " +
                                (config.start / vjsPlayer.duration()) * 100 +
                                "%'/>"
                        );
                });
                /*Setup hover zone to work with playhead*/
                var vjsControlBar =
                    document.getElementsByClassName("vjs-control-bar")[0];
                var hoverZone =
                    document.getElementsByClassName("hover-zone")[0];

                /* Prep video to hold control bar elements */
                vjsVideo.classList.add("vjs-user-inactive");
                vjsVideo.classList.remove("vjs-user-active");
                vjsVideo.appendChild(hoverZone);
                hoverZone.appendChild(vjsControlBar);
                vjsControlBar.classList.add("hidden");

                hoverZone.style = "visibility: visible";

                //Generate and place the fullscreen button
                var fsButton = document.getElementById("fs-button");
                vjsControlBar.appendChild(fsButton);
                fsButton.classList.remove("hidden");
            }
        });
        vjsPlayer.on("ended", () => {
            document.getElementById("end-section").classList.remove("hidden");
        });
    }, divConfig);
    /* End of Use Effect*/

    function mouseOver() {
        videoNode.current.parentElement.classList.add("vjs-user-active");
        videoNode.current.parentElement.classList.remove("vjs-user-inactive");
    }
    function mouseOut() {
        videoNode.current.parentElement.classList.add("vjs-user-inactive");
        videoNode.current.parentElement.classList.remove("vjs-user-active");
    }
    function fullscreenExperience() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }
    var isPlaying = true;
    function openModal(divID, titleLabel, metricSource, metricTitle, notes) {
        //console.log("Opening modal " + divID);
        document.getElementById("modal-section").classList.remove("hidden");
        var vjsPlayer = videojs(videoNode.current);
        vjsPlayer.pause();

        switch (divID) {
            case 0:
                handleShowOne();
                break;
            case 1:
                handleShowTwo();
                break;
            case 2:
                handleShowThree();
                break;
            case 3:
                handleShowFour();
                break;
            default:
        }

        trackMetrics(titleLabel, metricSource, metricTitle, notes);
    }
    function closeModal() {
        document.getElementById("modal-section").classList.add("hidden");
        var vjsPlayer = videojs(videoNode.current);
        vjsPlayer.play();
        handleCloseOne();
        handleCloseTwo();
        handleCloseThree();
        handleCloseFour();
        isPlaying = true;
    }
    function pauseVideo() {
        //console.log("bool " + isPlaying);
        var vjsPlayer = videojs(videoNode.current);
        if (isPlaying) {
            vjsPlayer.pause();
            //console.log("wasPlaying");
        } else {
            vjsPlayer.play();
            //console.log("wasntPlaying");
        }
        isPlaying = !isPlaying;
        //console.log("bool " + isPlaying);
    }
    function playVideo() {
        document.getElementById("overlay-button").classList.add("hidden");
        var vjsPlayer = videojs(videoNode.current);
        vjsPlayer.play();
    }
    function restartVideo() {
        var vjsPlayer = videojs(videoNode.current);
        vjsPlayer.currentTime(8);
        vjsPlayer.play();
    }
    function selectAudioPreference(id) {
        var buttonZone = document.getElementById("button-section");
        document
            .getElementById("button-section")
            .classList.remove("unclickable");
        var vjsPlayer = videojs(videoNode.current);
        vjsVideo = document.getElementById("main-video");
        vjsVideo.appendChild(buttonZone);

        document.getElementById("button-section-2").classList.add("hidden");
        switch (id) {
            case 0:
                vjsPlayer.muted(false);
                var tracks = vjsPlayer.textTracks();
                tracks[0].mode = "disabled";
                break;
            case 1:
                var tracks = vjsPlayer.textTracks();
                tracks[0].mode = "showing";
                vjsPlayer.muted(true);
                break;
            case 2:
                var tracks = vjsPlayer.textTracks();
                tracks[0].mode = "showing";
                vjsPlayer.muted(false);
                break;
            default:
            // code block
        }

        vjsPlayer.currentTime(6.5);
        vjsPlayer.play();
    }

    /************************************************************************/
    /* START OF HTML RENDERING */
    /************************************************************************/

    return (
        <div className="App">
            <div className="video-section">
                <video
                    disablePictureInPicture
                    ref={videoNode}
                    id="main-video"
                    className="video-js video-main-player"
                    controls
                    data-setup='{ "inactivityTimeout": 0 }'
                    preload="auto"
                    poster="image/Poster-Home.png"
                    playsInline
                >
                    <source
                        src="video/CC_HearingVideoExperience-WithIntro.mp4"
                        type="video/mp4"
                    />
                    <track
                        kind="captions"
                        src="video/Captions-WithIntro.vtt"
                        srcLang="en"
                        label="English"
                        default
                    ></track>
                </video>
                <div className="button-section unclickable" id="button-section">
                    <div
                        className="pause-button"
                        onClick={() => pauseVideo()}
                    ></div>
                    {divConfig.map(
                        (config, index) =>
                            visibleDivs[index] && (
                                <button
                                    type="button"
                                    key={index}
                                    id={config.divID}
                                    style={{
                                        position: "absolute",
                                        width: config.width + "%",
                                        height: config.height + "%",
                                        top: config.posY + "%",
                                        left: config.posX + "%",
                                    }}
                                    className="hotspot-zone"
                                    onClick={() =>
                                        openModal(
                                            config.id,
                                            config.titleLabel,
                                            config.metricSource,
                                            config.metricTitle,
                                            config.notes
                                        )
                                    }
                                ></button>
                            )
                    )}
                </div>
                <div
                    className="button-section-2 unclickable"
                    id="button-section-2"
                >
                    <div
                        className="third-button"
                        onClick={() => {
                            selectAudioPreference(0);
                            trackMetrics(
                                "Play With Audio",
                                "nav-audio-preferences",
                                "Play-With-Audio",
                                "Not a goal"
                            );
                        }}
                    ></div>
                    <div
                        className="third-button"
                        onClick={() => {
                            selectAudioPreference(1);
                            trackMetrics(
                                "Play With Captions",
                                "nav-audio-preferences",
                                "Play-With-Captions",
                                "Not a goal"
                            );
                        }}
                    ></div>
                    <div
                        className="third-button"
                        onClick={() => {
                            selectAudioPreference(2);
                            trackMetrics(
                                "Play With Both",
                                "nav-audio-preferences",
                                "Play-With-Both",
                                "Not a goal"
                            );
                        }}
                    ></div>
                </div>
                <div
                    className="hover-zone"
                    id="hover-zone"
                    onMouseOver={mouseOver}
                    onMouseOut={mouseOut}
                ></div>
            </div>
            <div className="end-section hidden" id="end-section">
                <button
                    className="end-button end-button-1"
                    onClick={() => {
                        window.open(
                            "https://www.carecredit.com/providercenter/resourcescenter",
                            "_blank"
                        );
                        trackMetrics(
                            "Explore More Tools",
                            "nav-video-end",
                            "Explore-More-Tools",
                            "This should be a goal"
                        );
                    }}
                ></button>
                <button
                    className="end-button end-button-2"
                    onClick={() => {
                        window.open(
                            "https://www.carecredit.com/providers/contact-team/",
                            "_blank"
                        );
                        trackMetrics(
                            "Enroll Now",
                            "nav-video-end",
                            "Enroll-Now",
                            "This should be a goal"
                        );
                    }}
                ></button>
                <button
                    className="end-button end-button-3"
                    onClick={() => {
                        restartVideo();
                        trackMetrics(
                            "Replay The Journey",
                            "nav-video-end",
                            "Replay-The-Journey",
                            "This should be a goal"
                        );
                    }}
                ></button>
            </div>
            <button
                className="fs-button hidden"
                id="fs-button"
                onClick={fullscreenExperience}
                type="button"
                title="Fullscreen"
                aria-disabled="false"
            >
                <Image
                    className="fullscreen-icon"
                    src={require("./image/fullscreen.svg").default}
                    height={25}
                    width={25}
                    onMouseOver={mouseOver}
                    onMouseOut={mouseOut}
                ></Image>
            </button>
            <div
                className="overlay-button"
                id="overlay-button"
                onClick={() => {
                    playVideo();
                    trackMetrics(
                        "Start Button Clicked",
                        "nav-video-control",
                        "Start-Button-Clicked",
                        "This should be a goal and also tracked as the qualifying click"
                    );
                }}
            ></div>
            <div className="modal-section hidden" id="modal-section">
                <Modal
                    show={showOne}
                    onHide={handleCloseOne}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal-1"
                    id="mainModalOne"
                >
                    <div className="sixty"></div>
                    <div className="fourty"></div>
                    <div className="content-area">
                        <div className="fifty left">
                            <Image
                                className="Modal-1-Text"
                                src={require("./image/Modal-1-Text.png")}
                                width={"70%"}
                            ></Image>
                            <Image
                                className="Modal-1-Button-1"
                                src={require("./image/Modal-1-Button-1.png")}
                                width={"60%"}
                                onClick={() => {
                                    window.open(
                                        "https://www.carecredit.com/providercenter/resourcescenter/promote/readytousecopy/",
                                        "_blank"
                                    );
                                    trackMetrics(
                                        "Access Digital Assets",
                                        "nav-modal-1",
                                        "Access-Digital-Assets",
                                        "This should be a goal"
                                    );
                                }}
                            ></Image>
                        </div>
                        <div className="fifty right">
                            <Image
                                src={require("./image/Button-ReturnToVideo.png")}
                                width={"40%"}
                                className="Close-Button"
                                onClick={() => {
                                    closeModal();
                                }}
                            ></Image>
                            <video
                                disablePictureInPicture
                                id="modal-1-video"
                                className="video-sub-player"
                                controls
                                preload="auto"
                                poster="image/Modal-1-VideoPoster.png"
                                width={"90%"}
                                onPlay={() => {
                                    trackMetrics(
                                        "Listen To Joan",
                                        "nav-modal-1",
                                        "Listen-To-Joan",
                                        "This should be a goal"
                                    );
                                }}
                            >
                                <source
                                    src="video/Joan-Quillin_HearingStories.mp4"
                                    type="video/mp4"
                                />
                            </video>
                            <Image
                                className="Modal-1-AddInfo"
                                src={require("./image/Modal-1-AddInfo.png")}
                                width={"50%"}
                            ></Image>
                        </div>
                    </div>
                </Modal>
                <Modal
                    show={showTwo}
                    onHide={handleCloseTwo}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal-2"
                    id="mainModalTwo"
                >
                    <div className="sixty"></div>
                    <div className="fourty"></div>
                    <div className="content-area">
                        <div className="fifty left">
                            <Image
                                className="Modal-2-Text"
                                src={require("./image/Modal-2-Text.png")}
                                width={"70%"}
                            ></Image>
                            <Image
                                className="Modal-2-Button-1"
                                src={require("./image/Modal-2-Button-1.png")}
                                width={"60%"}
                                onClick={() => {
                                    window.open(
                                        "https://www.carecredit.com/providers/insights/?industry=hearing&topic=&type=",
                                        "_blank"
                                    );
                                    trackMetrics(
                                        "Download Materials Menu",
                                        "nav-modal-2",
                                        "Download-Materials-Menu",
                                        "This should be a goal"
                                    );
                                }}
                            ></Image>
                            <Image
                                className="Modal-2-Button-2"
                                src={require("./image/Modal-2-Button-2.png")}
                                width={"60%"}
                                onClick={() => {
                                    window.open(
                                        "https://www.carecredit.com/providercenter/resourcescenter/promote/readytousecopy/ ",
                                        "_blank"
                                    );
                                    trackMetrics(
                                        "Order Promotional Materials",
                                        "nav-modal-2",
                                        "Order-Promotional-Materials",
                                        "This should be a goal"
                                    );
                                }}
                            ></Image>
                            <Image
                                className="Modal-3-Button-1"
                                src={require("./image/Modal-3-Button-1.png")}
                                width={"60%"}
                                onClick={() => {
                                    window.open(
                                        "./resources/TipsScript.pdf",
                                        "_blank"
                                    );
                                    trackMetrics(
                                        "Download Tips and Scripts",
                                        "nav-modal-3",
                                        "Download-Tips-Scripts",
                                        "This should be a goal"
                                    );
                                    //console.log("stuff");
                                }}
                            ></Image>
                        </div>
                        <div className="fifty right">
                            <Image
                                src={require("./image/Button-ReturnToVideo.png")}
                                width={"40%"}
                                className="Close-Button"
                                onClick={() => closeModal()}
                            ></Image>
                            <Image
                                className="Modal-2-FeatureImage"
                                src={require("./image/Modal-2-FeatureImage.png")}
                                width={"80%"}
                            ></Image>
                        </div>
                    </div>
                </Modal>
                <Modal
                    show={showThree}
                    onHide={handleCloseThree}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal-3"
                    id="mainModalThree"
                >
                    <div className="sixty"></div>
                    <div className="fourty"></div>
                    <div className="content-area">
                        <div className="fifty left">
                            <Image
                                className="Modal-3-Text"
                                src={require("./image/Modal-3-Text.png")}
                                width={"70%"}
                            ></Image>
                        </div>
                        <div className="fifty right">
                            <Image
                                src={require("./image/Button-ReturnToVideo.png")}
                                width={"40%"}
                                className="Close-Button"
                                onClick={() => closeModal()}
                            ></Image>
                            <Image
                                className="Modal-3-FeatureImage"
                                src={require("./image/Modal-3-FeatureImage.png")}
                                width={"65%"}
                            ></Image>
                        </div>
                    </div>
                </Modal>
                <Modal
                    show={showFour}
                    onHide={handleCloseFour}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="custom-modal-4"
                    id="mainModalOne"
                >
                    <div className="sixty"></div>
                    <div className="fourty"></div>
                    <div className="content-area">
                        <div className="fifty left">
                            <Image
                                className="Modal-4-Text"
                                src={require("./image/Modal-4-Text.png")}
                                width={"70%"}
                            ></Image>
                            <Image
                                className="Modal-4-Button-1"
                                src={require("./image/Modal-4-Button-1.png")}
                                width={"60%"}
                                onClick={() => {
                                    window.open(
                                        "https://www.carecredit.com/providers/custom-link/",
                                        "_blank"
                                    );
                                    trackMetrics(
                                        "Learn More About Custom Link",
                                        "nav-modal-4",
                                        "Learn-More-About-Custom-Link",
                                        "This should be a goal"
                                    );
                                }}
                            ></Image>
                        </div>
                        <div className="fifty right">
                            <Image
                                src={require("./image/Button-ReturnToVideo.png")}
                                width={"40%"}
                                className="Close-Button"
                                onClick={() => closeModal()}
                            ></Image>
                            <video
                                disablePictureInPicture
                                id="modal-1-video"
                                className="video-sub-player"
                                controls
                                preload="auto"
                                poster="image/Modal-4-VideoPoster.png"
                                width={"90%"}
                                onPlay={() => {
                                    trackMetrics(
                                        "Learn How Custom Link Works",
                                        "nav-modal-4",
                                        "Learn-How-Custom-Link-Works",
                                        "This should be a goal"
                                    );
                                }}
                            >
                                <source
                                    src="video/Custom-Link.mp4"
                                    type="video/mp4"
                                />
                            </video>
                            <Image
                                className="Modal-4-AddInfo"
                                src={require("./image/Modal-4-AddInfo.png")}
                                width={"50%"}
                            ></Image>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}

export default App;
